@if (event && visual) {
  <div
    class="event-card"
    [ngStyle]="{
      'background-image': 'url(' + encodedMainImage() + ')',
      'background-color': visual.backgroundColor
    }"
    (click)="goToEvent()"
  >
    <div class="top-info">
      <div class="date-info">{{ getEventDateInfoForCard() }}</div>
      <div class="rsvp-info">
        @if (event.isUserHost(loggedUserId)) {
          <i class="fa fa-crown"></i>
          <div class="rsvp-title">
            {{ event.getEventGoingRSVPTitle() | translate }}
          </div>
        } @else if (loggedUserId) {
          <i class="fa {{ event.getUserRSVPIcon(loggedUserId) }}"></i>
          <div class="rsvp-title">
            {{ event.getUserRSVPTitle(loggedUserId) | translate }}
          </div>
        }
      </div>
    </div>

    <div class="bottom-info">
      <div class="event-name">{{ event.name }}</div>
      <div class="host-info">
        <span class="hosted-by">{{ "APP.EVENT.HOSTED_BY" | translate }}</span>
        <app-user-avatar
          [user]="event.hostUser"
          [size]="30"
          [placeholderFontSize]="14"
        ></app-user-avatar>
        <span class="host-name">{{ event.hostUser?.name }}</span>
      </div>
    </div>

    @if (event.isMainPictureVideo()) {
      <div class="background-video-cont">
        <video class="key-visual-1" [muted]="'muted'" autoplay playsinline loop>
          <source [src]="event.mainPictureUrl" type="video/mp4" />
        </video>
      </div>
    }

    @if (showActionBar && event.isUserHost(loggedUserId)) {
      <div class="action-bar">
        <div class="action" (click)="onEdit($event)">
          <i class="fal fa-edit"></i>
          {{ "APP.EDIT" | translate }}
        </div>
        <div class="action" (click)="onLink($event)">
          <i class="fal fa-link"></i>
          {{ "APP.LINK" | translate }}
        </div>
        <div class="action" (click)="onDuplicate($event)">
          <i class="fal fa-copy"></i>
          {{ "APP.DUPLICATE" | translate }}
        </div>
      </div>
    }
  </div>
}
